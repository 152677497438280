body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1a3d7c;
  color: #FFFFFF;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.header-container {
  align-items: center;
}

.site-container {
  padding-bottom: 60px;
}

.wallet-adapter-button:hover {
  background: #3145B4;
}

.mint-container {
  max-width: 550px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.mint-btn-container .wallet-adapter-button {
  margin-top: 20px;
}

.unlimited-mints {
  background: #fdfdff;
  display: inline-block;
  border-radius: 4px;
  font-size: 14px;
  padding: 4px 10px;
  margin: 0;
  text-transform: uppercase;
  color: #0a1812;
  font-weight: 500;
  letter-spacing: 1px;
}

.mint-container > div {
  background: #3D54C1 !important;
  border-radius: 12px !important;
}

.progress-bar {
  max-width: 400px !important;
  margin: 0 auto 30px !important;
}

.mint-btn-container button:not(.wallet-adapter-button):hover {
  background-color: #34cd94 !important;
}

.mint-btn-container button.wallet-adapter-button {
  background-color: #30409b !important;
}

@media only screen and (min-width: 769px) {
  .header-container {
    justify-content: space-between !important;
  }

  .counter-container .MuiPaper-elevation1 {
    padding: 10px 10px 15px;
    width: calc(20% - 10px);
    background: #b8d1f3 !important;
    color: #3D54C1;
    text-transform: uppercase;
    font-size: 0.8rem;
    box-sizing: border-box;
  }

  .counter-container .MuiPaper-elevation1 h1 {
    font-size: 30px;
    margin: 0;
  }
}

@media only screen and (max-width: 768px) {
  .logo {
    height: 20px !important;
  }


  .header-container {
    display: flex;
    justify-content: center;
    gap: 15px;
  }

  .wallet-connect-container > div {
    font-size: 15px !important;
  }

  .wallet-adapter-button {
    font-size: 14px !important;
    height: 40px !important;
    padding: 0 10px !important;
  }

  .preview-gif-container img {
    height: auto; 
    width: 100%;
  }

  .mint-container h2 {
    font-size: 30px;;
  }

  .price-container {
    transform: scale(0.7) translateX(-14px);
  }

  .mint-container > .MuiPaper-root {
    width: calc(100% - 40px);
    min-width: auto;
  }

  .counter-container .MuiPaper-elevation1 {
    padding: 10px 10px 15px;
    width: calc(25% - 10px);
    background: #b8d1f3 !important;
    color: #3D54C1;
    text-transform: uppercase;
    font-size: 0.8rem;
    box-sizing: border-box;
  }

  .counter-container .MuiPaper-elevation1 h1 {
    font-size: 30px;
    margin: 0;
  }
}

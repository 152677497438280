@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700&display=swap');

/* Customize your main colors in :root variables */
:root {
  --main-background-color: #343A50;
  --card-background-color: #51576D;
  --card-background-lighter-color: #7A7F92;
  --main-text-color:#F7F6F4;
  --title-text-color:#3CBA8B;
}

body{
  background-color: #E6F2FF;
  font-family: 'Rubik', 'Helvetica', 'Sans-Serif', cursive;
  font-size: 1.2em;
}

h2 {
  font-size: 2.6rem;
}

h2, p{
  color: var(--main-text-color);
}

h3{
font-weight: normal;
}



